












































































import { Vue, Component } from 'vue-property-decorator'
import ws, { SocketMsg } from '@/services/socket'
import freebieService, { FreebieOpt, FreebieOptionRes } from '@/services/freebieService'
import { OptionMsgContent, PortfolioGroup } from '@/services/data'
import utility from '@/common/utility'
import settingService, { SettingValueModel } from '@/services/settingService'
import NumberRowEditor from '@/components/NumberRowEditor.vue'
import NumberRowSelector from '@/components/NumberRowSelector.vue'

@Component({
  components: {
    NumberRowEditor,
    NumberRowSelector
  }
})
export default class FreebieDetector extends Vue {
  OptionRes: FreebieOptionRes = null
  rangeOnly = true
  appendIfUpdated = false
  isLoading = false
  EditDialog = false
  Editing: SettingValueModel = {
    Name: null,
    Value: null,
    ValueType: 'Number'
  }

  async mounted () {
    ws.Server.init()
    ws.Local.stopAutoConnect()
    ws.Server.addMessageCallBack(this.msgCallBack)
    ws.Server.sendContext({ page: 'optlog' })
    await this.getOptions()
  }

  async showEditing (name: string) {
    this.Editing.Value = null
    this.Editing.Name = name
    this.EditDialog = true
    this.Editing.Value = await this.getSettingsAsync(name)
  }

  async saveUserSetting () {
    this.Editing.Value = this.Editing.Value.toString()
    const res = await settingService.addOrUpdateUserSettingAsync([this.Editing])
    if (res.Error) {
      await this.$alert(res.Error)
    } else {
      this.EditDialog = false
      await this.getOptions()
    }
  }

  async getSettingsAsync (name: string) {
    const res = await settingService.readUserSettingsByNamesAsync([name])
    for (const item of res.Result) {
      if (item.Name === name) {
        return item.Value
      }
    }
  }

  async rangeOnlyChanged () {
    await this.getOptions()
  }

  async appendIfUpdatedChanged () {
    await this.getOptions()
  }

  async getOptions () {
    this.isLoading = true
    const res = await freebieService.getOptions()
    if (res.Result) {
      if (this.rangeOnly) {
        res.Result.SymbolOpts.forEach(p => {
          if (p.Range) {
            p.Options = p.Options.filter(q => q.Otm >= p.Range.Low && q.Otm <= p.Range.High)
          }
        })
      }
      if (this.appendIfUpdated) {
        const sort = (a: FreebieOpt, b: FreebieOpt): number => {
          if (a.Time > b.Time) return -1
          if (a.Time < b.Time) return 1
          return 0
        }
        for (const item of res.Result.SymbolOpts) {
          item.Options.sort(sort)
          utility.freezeArrayItems(item.Options)
        }
      }
      this.OptionRes = res.Result
    }
    this.isLoading = false
  }

  destroyed (): void {
    ws.Server.removeMessageCallBack(this.msgCallBack)
  }

  getOtm (right: string, strike: number, stockPrice: number) {
    return right === 'C'
      ? Math.floor(((strike / stockPrice) - 1) * 100)
      : Math.floor((1 - (strike / stockPrice)) * 100)
  }

  private updateRowValue (sub: FreebieOpt, content: OptionMsgContent) {
    sub.OptionPrice = Math.floor(content.Value * 10000) / 10000.0
    sub.Time = content.LastTime
    sub.StockPrice = content.StockPrice
    sub.Otm = content.Otm
    sub.Target = content.Target
  }

  private async msgCallBack (msg: SocketMsg) {
    if (this.isLoading) {
      return
    }
    if (msg.Action === 'resQueue' && msg.Content === 'changed') {
      if (!this.appendIfUpdated) {
        await this.getOptions()
        return
      }
    }
    if (msg.Action === 'resFreebie') {
      const appendIfUpdated = this.appendIfUpdated
      if (!this.OptionRes || !this.OptionRes.SymbolOpts) {
        return
      }
      for (const item of this.OptionRes.SymbolOpts) {
        const findOpt = item.Options.find(p => p.Uid === msg.MsgId)
        if (findOpt != null) {
          const content = msg.Content as OptionMsgContent
          if (appendIfUpdated) { // Log mode
            const newSub = Object.assign({}, findOpt)
            this.updateRowValue(newSub, content)
            const index = this.findLogIndex(item.Options, newSub)
            if (index === -1) {
              item.Options.push(newSub)
            } else {
              item.Options.splice(index, 0, newSub)
            }
            if (item.Options.length > 500) {
              item.Options.pop()
            }
          } else { // Monitor mode
            this.updateRowValue(findOpt, content)
          }
          for (const sub of item.Options) {
            if (findOpt !== sub && !appendIfUpdated) {
              sub.Time = content.LastTime
              sub.StockPrice = content.StockPrice
              sub.Otm = this.getOtm(sub.CallPut, sub.Strike, sub.StockPrice)
            }
          }
        }
      }
    }
  }

  private findLogIndex (opts: FreebieOpt[], newSub: FreebieOpt): number {
    if (opts.length > 0 && newSub.Time >= opts[0].Time) return 0
    for (let i = 0; i < opts.length; i++) {
      if (i + 1 < opts.length) {
        if (newSub.Time <= opts[i].Time && newSub.Time >= opts[i + 1].Time) {
          return i + 1
        }
      }
    }
    return -1
  }
}
